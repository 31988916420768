import { useEffect } from 'react';

const useScript = (url: string, onload:any) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = onload;

    document.body.appendChild(script);
  }, [url]);
};

export default useScript;
