import {useEffect, useState, useContext, useCallback} from "react";
import {
  DataGridPro,
  GridCellParams,
  GridFilterItem,
  GridCellEditCommitParams,
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import {Box} from "@mui/system";
import {useTheme} from "@emotion/react";
import {Theme as MuiTheme} from "@mui/material/styles";
import {Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//import {MultiSelectOperator} from "@mui/x-data-grid-pro";
// Hooks
import {useSnackbar} from "notistack";

// Internal
import CustomToolbar from "./toolbar/customToolbar";
import validate from "./dataTableValidators";
import sendRequest from "../services/sendRequest";
import {PresetFilter} from "../pages/dataPage";
import {
  secoundryChannelSelectOperator,
  linearChannelSelectOperator,
  attributeSelectOperator,
  attributeHierarchySelectOperator,
  DateRangeSelectOperator,
  RangeSelectOperator,
} from "./toolbar/multipleSelect";
import {
  attributesList,
  attributeHierarchyList,
  flattenTree,
} from "../utils/dataUtils";

// Contexts
import {
  AttributeContext,
  AttributeContextType,
} from "../context/attributeContextProvider";

interface DataTableProps {
  rawData: any;
  loading: boolean;
  setLoading: Function;
  filters: GridFilterItem[];
  setFilters: Function;
  applyFilters: Function;
  openPresetFilterSaveDialogue: Function;
  openImportDisplay: Function;
  presetFilterList: PresetFilter[];
  modelMetadata: any;
  handleGoogleSheetsExport: Function;
  limit: number;
  setColumnOrder: Function;
  deleteFilterConfiguration: Function;
  setImportMenuOpen: Function;
  apiRef: any;
}

declare module "@emotion/react" {
  export interface Theme extends MuiTheme {}
}

const useUpdateBaserock = () => {
  return useCallback(async ({id, field, value}: GridCellEditCommitParams) => {
    const data = {
      baserock_id: id,
      [field.toString()]: value,
    };
    const response = await sendRequest({
      requestType: "patch",
      requestHeader: `master/${id}/`,
      requestData: data,
    });
    return response;
  }, []);
};

const useRecentUpdateBaserock = () => {
  return useCallback(async ({id, field, value}: GridCellEditCommitParams) => {
    const data = {
      baserock_id: id,
    };
    const response = await sendRequest({
      requestType: "get",
      requestHeader: `master/recent_update/${id}/`,
      requestData: data,
    });
    return response;
  }, []);
};

const DataTable = ({
  rawData,
  loading,
  apiRef,
  setLoading,
  filters,
  setFilters,
  applyFilters,
  openPresetFilterSaveDialogue,
  openImportDisplay,
  presetFilterList,
  modelMetadata,
  handleGoogleSheetsExport,
  limit,
  setColumnOrder,
  deleteFilterConfiguration,
  setImportMenuOpen,
}: DataTableProps) => {
  const [dataProcessed, setDataProcessed] = useState<any[] | null>();
  const [colsProcessed, setColsProcessed] = useState<any[] | null>();
  const [dataTypes, setDataTypes] = useState<Record<string, string>>();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const theme: MuiTheme = useTheme();
  const {attributeTree} = useContext(AttributeContext) as AttributeContextType;
  const update = useUpdateBaserock();
  const recent_update = useRecentUpdateBaserock();

  useEffect(() => {
    (async () => {
      try {
        if (rawData) {
          // the master view has results.results for some reason.
          if (rawData.results.results) {
            rawData.results = rawData.results.results;
          }

          // const data: any = await processData(rawData)
          const columns: any[] = await processColumns(rawData.results);

          setColsProcessed(columns);
          setDataProcessed(rawData.results);
          enqueueSnackbar("Data succcessfully fetched!", {variant: "success"});
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        enqueueSnackbar("There was an error processing the data and columns", {
          variant: "warning",
        });
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData]);

  const handleCellEditCommit = async (
    { id, field, value }: GridCellEditCommitParams,
    event: any
  ) => {
    event.defaultMuiPrevented = true;
    
    const orig = dataProcessed?.find((row) => row.id === id);
    const baserockId = orig["baserock_id"];

    if (orig == null) return;
    if (orig[field] === value && field !== 'linear_channel' && field !== 'secondary_channel') {
      return;
    }

    // make sure that the field has a set datatype
    const dtype = dataTypes?.[field]!;

    if (dtype === undefined) return;
    if (value === "" && dtype !== "string") value = null;
  
  // Handle linear_channel specifically
  if (field === "linear_channel") {
    if (value === 'CLEAR CELL') {
      // Set value to empty string
      value = '';
      // Additional logic to handle clearing selection, if any
    } else {
      // Manage existing value
      let newLinearChannelArray: string[] = [];
      if (orig.linear_channel) {
        const linearChannelArray = orig.linear_channel.split(', ').map((item: string) => item.trim()).sort();
        if (linearChannelArray.length > 0 && value != null) {
          if (linearChannelArray.includes(value)) {
            // Remove the value if it exists
            newLinearChannelArray = linearChannelArray.filter((item: string) => item !== value);
          } else {
            // Add the value if it does not exist
            newLinearChannelArray = [...linearChannelArray, value];
          }
        } else if (value != null) {
          // If there are no existing values, just add the new value
          newLinearChannelArray = [value];
        }
      } else if (value != null) {
        // Handle case where there are no existing values
        newLinearChannelArray = [value];
      }
      value = newLinearChannelArray.join(', '); // Convert array to semicolon-separated string
    }

    const res = await update({ id: baserockId, field, value });
      // Handle the response as needed
      if (res) {
        setDataProcessed((prev) =>
          prev!.map((row) =>
            row.id === id ? { ...row, [field]: res[field] } : row
          )
        );
        enqueueSnackbar("updated successfully!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Error updating", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      // Return early to avoid other field processing
      return;
    }

  // Handle linear_channel specifically
  if (field === "secondary_channel") {
    if (value === 'CLEAR CELL') {
      // Set value to empty string
      value = '';
      // Additional logic to handle clearing selection, if any
    } else {
      // Manage existing value
      let newSecondryChannelArray: string[] = [];
      if (orig.secondary_channel) {
        const secondryChannelArray = orig.secondary_channel.split(', ').map((item: string) => item.trim()).sort();
        if (secondryChannelArray.length > 0 && value != null) {
          if (secondryChannelArray.includes(value)) {
            // Remove the value if it exists
            newSecondryChannelArray = secondryChannelArray.filter((item: string) => item !== value);
          } else {
            // Add the value if it does not exist
            newSecondryChannelArray = [...secondryChannelArray, value];
          }
        } else if (value != null) {
          // If there are no existing values, just add the new value
          newSecondryChannelArray = [value];
        }
      } else if (value != null) {
        // Handle case where there are no existing values
        newSecondryChannelArray = [value];
      }
      value = newSecondryChannelArray.join(', '); // Convert array to semicolon-separated string
    }
    const res = await update({ id: baserockId, field, value });
      // Handle the response as needed
      if (res) {
        setDataProcessed((prev) =>
          prev!.map((row) =>
            row.id === id ? { ...row, [field]: res[field] } : row
          )
        );
        enqueueSnackbar("updated successfully!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Error updating", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
      // Return early to avoid other field processing
      return;
    }

    if (dtype !== "attribute") {
      // For normal data (not the dropdown Single Select) check if the enter key was the event trigger
      // prevents accidental modification of a cell by clicking out of it
      if (event.key !== "Enter" && event.key !== "Tab") return;
      if (value !== null) {
        let error;
        if (field === "duration") {
          error = validate("duration", value.toString());
        } else if (
          field === "comp_ver_duration" ||
          field === "orig_ver_duration" ||
          field === "comp_ver_ad_breaks" ||
          field === "orig_ver_ad_breaks"
        ) {
          error = validate("time", value.toString());
        } else if (field === "critical_rating") {
          error = validate("critical_rating", value.toString());
        } else if (field === "yt_url") {
          error = validate("YTUrl", value.toString());
        } else if (field === "wp_url") {
          error = validate("wp_url", value.toString());
        } else if (field === "bbfc_url") {
          error = validate("bbfc_url", value.toString());
        } else {
          error = validate(dtype, value.toString());
        }
        if (error !== null) {
          enqueueSnackbar(error, {variant: "warning", autoHideDuration: 3000});
          return;
        }
      }
    }

    try {
      const snackbar = enqueueSnackbar(`Updating Baserock...`, {
        variant: "info",
        persist: true,
        action: (
          <div>
            <CircularProgress />
          </div>
        ),
      });

      if (field === "origin_country") {
        if (orig != null && orig.origin_country != null) {
            const origin_country_arry = orig.origin_country.split(';').map((item: string) => item.trim()).sort();
    
            for (let i = 0; i < origin_country_arry.length; i++) {
                origin_country_arry[i] = origin_country_arry[i].trim();
            }

            var new_origin_country_arry = [];
            if (origin_country_arry.length > 0 && value != null && origin_country_arry.includes(value)) {
                new_origin_country_arry = origin_country_arry.filter((item: string) => !(item == value));
            } else {
                new_origin_country_arry = [...origin_country_arry, value];
            }
        }
        value = new_origin_country_arry;
    
      }

      const res = await update({ id: baserockId, field, value });

      if (field === "origin_country") {
        const res1 = await recent_update({ id: baserockId, field, value });
        res.origin_country = res1[0].origin_country;
      }     


      closeSnackbar(snackbar);
      if (res) {
        setDataProcessed((prev) =>
          prev!.map((row) =>
            row.id === id ? {...row, [field]: res[field]} : row
          )
        );
        enqueueSnackbar("Succesful Update!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Error updating baserock!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const processColumns = async (rawData: any, orig?: any) => {
    let dataTypeMap: Record<string, string> = {};
    const columns = Object.keys(rawData[0]).flatMap((key: string) => {
      const columnsToSkip: string[] = [
        "attributes",
        "exploitation",
        "restrictions",
        "rights_in",
      ];

      if (columnsToSkip.includes(key)) return [];

      // get the label from model metadata
      const item = modelMetadata.filter((item: any) => item.option === key)[0];

      // ignore data that doesn't appear in options.
      if (!item) return [];

      dataTypeMap[key] = item.dataType;

      let headerName = item?.label.join(" > ") || key;

      // Backend table has primary_channel, frontend has AVOD Batch Number
      if (key === "primary_channel") {
        headerName = "AVOD Batch Number";
      }

      // Backend table has secondary_channel, frontend has AVOD Platform
      if (key === "secondary_channel") {
        headerName = "AVOD Platform";
      }
      
      const cellDefault = {
        field: key,
        headerName: headerName,
        editable: !item?.readonly,
        flex: 1,
        minWidth: 250,
        hide: key === "id",
        renderCell: (params: GridCellParams) => (
          <Tooltip
            placement="bottom-start"
            title={params.value}
            children={<div>{params.value}</div>}
            enterDelay={2000}
          />
        ),
      };

      if (item?.dataType === "attribute") {
        return {
          ...cellDefault,
          type: "singleSelect",
          ...(cellDefault.editable && {
            valueOptions: Object.keys(attributesList).includes(key)
            ? attributeTree[attributesList[key]].map((item) => item.value).sort()
              : [],
          }),
          filterOperators: attributeSelectOperator,
          filterOperatorProps: Object.keys(attributesList).includes(key)
            ? {
                attributeTree: flattenTree({
                  treeArr: attributeTree[attributesList[key]],
                }),
              }
            : undefined,
        };
      } else if (
        (item.dataType === "string" || item.dataType === "date") &&
        (item.option === "rights_in_end_date" ||
          item.option === "rights_in_start_date" ||
          item.option === "date_delivered_to_littledot" ||
          item.option === "released_date")
      ) {
        return {
          ...cellDefault,
          type: "string", // You can adjust this based on your requirements
          // ...(cellDefault.editable && {
          //   valueOptions: [], // Add your string-specific value options here
          // }),
          filterOperators: DateRangeSelectOperator,
          // Add other properties specific to the string data type as needed
        };
      } else if (
        item.dataType === "string" &&
        (item.option === "comp_ver_duration" ||
          item.option === "orig_ver_duration" ||
          item.option === "duration")
      ) {
        return {
          ...cellDefault,
          type: "string", // You can adjust this based on your requirements
          // ...(cellDefault.editable && {
          //   valueOptions: [], // Add your string-specific value options here
          // }),
          filterOperators: RangeSelectOperator,
          // Add other properties specific to the string data type as needed
        };
      } else if (item?.dataType === "attributeHierarchy") {
        return {
          ...cellDefault,
          filterOperators: attributeHierarchySelectOperator,
          filterOperatorProps: Object.keys(attributeHierarchyList).includes(key)
            ? {
                attributeTree: flattenTree({
                  treeArr: attributeTree[attributeHierarchyList[key]],
                }),
              }
            : undefined,
        };
      } else if (key === "origin_country") { // Handle origin_country field
        return {
          ...cellDefault,
          type: "singleSelect",
          valueOptions: orig?.origin_country.split(';').map((item: string) => item.trim()).sort(),
          // Assuming the options are stored as a string separated by ";"
          filterOperators: attributeSelectOperator,
        };
      
      } else if (key === "linear_channel") {
        return {
          ...cellDefault,
          type: "singleSelect",
          valueOptions: [
            "CLEAR CELL",
            "Don't Tell The Bride",
            "History Hit",
            "Real Crime Alpha",
            "Real Crime Beta",
            "Real Crime Gamma",
            "Real Crime WW",
            "Real Life",
            "Real Stories",
            "Real Wild",
            "The Chat Show Channel",
            "Wonder",
          ],
          
          // Assuming the options are stored as a string separated by ";"
          filterOperators: linearChannelSelectOperator,
        };

      } else if (key === "secondary_channel") {
        return {
          ...cellDefault,
          type: "singleSelect",
          valueOptions: [
            "CLEAR CELL",
            "InsightTV",
            "LG",
            "Plex",
            "Roku",
            "Samsung AU",
            "STV",
            "Veely",
            "Vizio",
          ],
          
          // Assuming the options are stored as a string separated by ";"
          filterOperators: secoundryChannelSelectOperator,
        };

      } else if (key === "rights_in") {
        return {
          ...cellDefault,
        };

      
      } else {
        return {
          ...cellDefault,
        };
      }
    });

    setDataTypes(dataTypeMap);
    return columns;
  };

  return (
    <>
      <Box
        sx={{
          height: "85vh",
          width: "100%",
          ".restricted": {
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
            "& .super-app-theme--header": {
              backgroundColor: "rgba(255, 7, 0, 0.55)",
            },
          },
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          rows={dataProcessed ? dataProcessed : []}
          columns={colsProcessed ? colsProcessed : []}
          // pagination
          pageSize={limit}
          rowsPerPageOptions={[]}
          rowCount={rawData ? rawData.count : 0}
          // filtering
          onFilterModelChange={(model) => setFilters(model.items)}
          // editing
          onCellEditCommit={handleCellEditCommit}
          checkboxSelection
          // other
          onColumnOrderChange={(params, model, details) =>
            setColumnOrder(
              details.api
                .getVisibleColumns()
                .map(({field}: {field: string}) => field)
            )
          }
          onColumnVisibilityModelChange={(params, model) =>
            setColumnOrder(
              model.api
                .getVisibleColumns()
                .map(({field}: {field: string}) => field)
            )
          }
          getRowClassName={(params) =>
            !params.row.restriction_list ? "" : "restricted"
          }
          loading={loading}
          getRowId={(row: any) => row.id}
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
          }}
          componentsProps={{
            toolbar: {
              filters: filters,
              setFilters: setFilters,
              applyFilters: applyFilters,
              openPresetFilterSaveDialogue: openPresetFilterSaveDialogue,
              openImportDisplay: openImportDisplay,
              presetFilterList: presetFilterList,
              modelMetadata: modelMetadata,
              handleGoogleSheetsExport: handleGoogleSheetsExport,
              loading: loading,
              deleteFilterConfiguration: deleteFilterConfiguration,
              setImportMenuOpen: setImportMenuOpen,
            },
            filterPanel: {
              columnsSort: "asc",
              sx: {
                "& .MuiDataGrid-filterForm": {width: 520, minWidth: 520},
              },
            },
          }}
        />
      </Box>
    </>
  );
};

export default DataTable;
