import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { useSnackbar, SnackbarKey } from 'notistack';

type propTypes = {
  snackbarKey: SnackbarKey;
}

const SnackbarCloseButton = ({ snackbarKey }: propTypes) => {
  
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose
        style={{color: "white"}}
      />
    </IconButton>
  );
}

export default SnackbarCloseButton;
