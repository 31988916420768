type ValidationFunction = (input: string) => string | null;
const maxLength = 16;
const dateCheck = (input: string) => {
  const re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  return re.test(input)
    ? null
    : "Input must be a valid date in the format YYYY-MM-DD";
};

const lengthCheck = (input: string) => {
  const re = /^[\x00-\x7F]*$/;
  return re.test(input) && input.length <= maxLength
    ? null
    : `Input must only contain ASCII characters and have a maximum length of ${maxLength} characters`;
};

const intCheck = (input: string) => {
  return isNaN(input as any) ? "Input must be an integer" : null;
};

const stringCheck = (input: string) => {
  const re = /^[\x00-\x7F]*$/;
  return re.test(input) ? null : "Input must only contain ASCII characters";
};

const timeCheck = (input: string) => {
  if (input === "") {
    return null;
  }

  const timeValues = input.split("; ");

  for (const value of timeValues) {
    const re = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)\.(\d{3})$/;

    if (re.test(value)) {
      // Extract the components
      const [, hours, minutes, seconds, milliseconds]: any = value.match(re);

      // Convert to numbers
      const hoursValue = parseInt(hours, 10);
      const minutesValue = parseInt(minutes, 10);
      const secondsValue = parseInt(seconds, 10);
      const millisecondsValue = parseInt(milliseconds, 10);

      // Validate the components
      if (
        hoursValue >= 0 &&
        hoursValue <= 23 &&
        minutesValue >= 0 &&
        minutesValue <= 59 &&
        secondsValue >= 0 &&
        secondsValue <= 59 &&
        millisecondsValue >= 0 &&
        millisecondsValue <= 999
      ) {
        // Continue to the next value
        continue;
      }
    }

    // If any value is not valid, return an error message
    return "Input must be a valid time in the format hh:mm:ss.mmm";
  }

  // If all values are valid
  return null;
};

const duration = (input: string): string | null => {
  if (input === "") {
    return null;
  }

  const re = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)\.(\d{3})$/;

  if (re.test(input)) {
    // Extract the components
    const [, hours, minutes, seconds, milliseconds]: any = input.match(re);

    // Convert to numbers
    const hoursValue = parseInt(hours, 10);
    const minutesValue = parseInt(minutes, 10);
    const secondsValue = parseInt(seconds, 10);
    const millisecondsValue = parseInt(milliseconds, 10);

    // Validate the components
    if (
      hoursValue >= 0 &&
      hoursValue <= 23 &&
      minutesValue >= 0 &&
      minutesValue <= 59 &&
      secondsValue >= 0 &&
      secondsValue <= 59 &&
      millisecondsValue >= 0 &&
      millisecondsValue <= 999
    ) {
      return null;
    }
  }

  return "Invalid duration format. Please use HH:mm:ss.SSS";
};

const validateYouTubeUrl = (input: string) => {
  if (input === "") {
    return null; // Allow null values
  }
  // Regular expression for a typical YouTube URL
  const youtubeUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  if (youtubeUrlRegex.test(input)) {
    // Extract the video ID from the URL
    const [, videoId]: any = input.match(youtubeUrlRegex);

    // You can perform additional validation or return the video ID if needed
    return null;
  }

  return "Input must be a valid YouTube URL.";
};

const validateWikipediaUrl = (input: string) => {
  if (input === "") {
    return null; // Allow null values
  }
  // Regular expression for a typical Wikipedia article URL
  const wikipediaUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?en\.wikipedia\.org\/wiki\/([^\/\n\s]+)/;

  if (wikipediaUrlRegex.test(input)) {
    // Extract the article title from the URL
    const [, articleTitle]: any = input.match(wikipediaUrlRegex);

    // You can perform additional validation or return the article title if needed
    return null;
  }

  return "Input must be a valid Wikipedia article URL.";
};
const validateBBFCUrl = (input: string) => {
  if (input === "") {
    return null; // Allow null values
  }

  const bbfcUrlRegex = /^https:\/\/www\.bbfc\.co\.uk\//;

  if (bbfcUrlRegex.test(input)) {
    // You can perform additional validation or return true if needed
    return null;
  }

  return "Input must be a valid BBFC movie detail page URL.";
};

const validator: any = {
  date: dateCheck,
  integer: intCheck,
  string: stringCheck,
  time: timeCheck,
  critical_rating: lengthCheck,
  YTUrl: validateYouTubeUrl,
  wp_url: validateWikipediaUrl,
  bbfc_url: validateBBFCUrl,
  duration: duration,
};

const validate = (dataType: string, value: string) => {
  return dataType in validator
    ? validator[dataType]?.(value)
    : `Datatype '${dataType}' validation not supported!`;
};

export default validate;
