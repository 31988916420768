import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useState } from "react"


const ImportMenuDialog = ({open, close, handleImport}:{open: boolean, close: Function, handleImport: Function}) => {

    const [url, setUrl] = useState('')

    return (
        <Dialog
            open={open}
            fullWidth
        >
            <DialogTitle>Import From Google Sheet</DialogTitle>
            <DialogContent>
                <TextField 
                    autoFocus
                    margin="dense"
                    label="Enter Google Sheets URL Here..."
                    fullWidth
                    onChange={(e: any) => setUrl(e.target.value)}
                    type='url'
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleImport(url)}
                    disabled={url.length < 1}
                >
                    Import
                </Button>
                <Button 
                    onClick={() => close()}
                >Close</Button>
            </DialogActions>

        </Dialog>
    )

}

export default ImportMenuDialog
