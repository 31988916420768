// External
import { Routes, Route, Navigate } from "react-router-dom";

// Styles
import { DrawerHeader, Main } from '../styles/theme'

// Contexts
import AttributeContextProvider from '../context/attributeContextProvider';

// Pages
import MUIDrawer from '../navigation/drawer'
import NotFound from '../pages/notFoundPage';
import LoginPage from '../pages/loginPage';
import AccountPage from '../pages/accountPage';
import DataPage from '../pages/dataPage';
import PrivateRoutes from '../auth/privateRoutes';
import AssetPage from "../pages/assetPage";


interface AppRoutesProps {
  open: boolean
  setOpen: Function
  setDarkMode: Function
  darkMode: boolean
}


const AppRoutes = ({ open, setOpen, setDarkMode, darkMode }: AppRoutesProps) =>
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route element={<PrivateRoutes />}>
      <Route element={<AttributeContextProvider />}>
        <Route path="/*" element={
          <>
            <MUIDrawer
              open={open}
              setOpen={setOpen}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <Main open={open} >
              <DrawerHeader />
              <Routes>
                <Route path="/dataPage" element={<DataPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/asset" element={<AssetPage />} />
                <Route path="/" element = {<LoginPage/>} />
                <Route path="*" element={<Navigate to="404" />} />
              </Routes>
            </Main >
          </>
        } />
      </Route>
    </Route>
    <Route path="/404" element={<NotFound />} />
  </Routes>

export default AppRoutes
