import * as React from "react";
import Box from "@mui/material/Box";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {GridFilterInputValueProps, useGridRootProps} from "@mui/x-data-grid";
import SyncIcon from "@mui/icons-material/Sync";

const dataGridProps: any = {
  filterDebounceMs: 300,
  // other DataGrid properties...
};

export default function BeforeAfterFilter(props: GridFilterInputValueProps) {
  const rootProps = useGridRootProps();
  const {item, applyValue, focusElementRef = null} = props;

  const filterTimeout = React.useRef<any>();
  const [filterValueState, setFilterValueState] = React.useState<string>(
    item.value ?? ""
  );
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? "";
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (newDate: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState(newDate);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({...item, value: newDate});
    }, dataGridProps.filterDebounceMs);
  };

  const handleDateChange: TextFieldProps["onChange"] = (event) => {
    const newDate = event.target.value;
    updateFilterValue(newDate);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="date-input"
        variant="standard"
        value={filterValueState}
        onChange={handleDateChange}
        type="date" // Use "date" type for date input
        inputRef={focusElementRef}
        InputProps={applying ? {endAdornment: <SyncIcon />} : {}}
      />
    </Box>
  );
}
