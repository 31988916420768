// External
import {useEffect, useState, FC} from "react";

// External
import {useGridApiContext, useGridApiRef} from "@mui/x-data-grid-pro";

// MUI
import {GridFilterItem} from "@mui/x-data-grid";

// Hooks
import {useSnackbar} from "notistack";

// Pages
import DataTable from "../tables/dataTable";

// Internal
import sendRequest from "../services/sendRequest";
import {DATA_PAGE_PAGE_LIMIT} from "../settings";
import SavePresetFilterDialog from "../alerts/savePresetFilterDialog";
import {getModelMetadata} from "../utils/dataUtils";
import ExportResultsDialog from "../alerts/exportResultsDialog";
import ImportMenuDialog from "../alerts/importMenuDialog";
import ImportDisplay from "../alerts/importDisplay";

export interface Filter {
  id?: number;
  name: string[] | undefined[];
  type?: "string" | "integer" | "float" | "date" | "field";
  condition?: string;
  value?: string | number;
}

export interface PresetFilter {
  name: string;
  filterString: string;
}

const DataPage: FC = () => {
  const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [modelMetadata, setModelMetadata] = useState<any>();
  const [filters, setFilters] = useState<GridFilterItem[] | []>([]);
  const [tableData, setTableData] = useState<{} | null | false>();
  const [savePresetDialogueOpen, setSavePresetDialogueOpen] = useState(false);
  const [presetFilterList, setPresetFilterList] = useState<PresetFilter[] | []>(
    []
  );
  const [columnOrder, setColumnOrder] = useState<string[]>();
  const [exportResultsDialogString, setExportResultsDialogString] =
    useState("");
  const [importMenuOpen, setImportMenuOpen] = useState(false);
  const [job, setJob] = useState<any>(null);
  const [importDisplayOpen, setImportDisplayOpen] = useState<boolean>(false);
  const apiRef = useGridApiRef();
  useEffect(() => {
    // getting data needs to be async, as they rely on eachother.
    (async () => {
      setLoading(true);
      try {
        // 2. get metadata for specific model from server
        setModelMetadata(await getModelMetadata({model: "master"}));
        // 3. get preset filters from server
        await getPresetFilters();
        // 4. get model data from server
        await getData();
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // gets list of preset filters from server
  const getPresetFilters = async () => {
    const data = await sendRequest({
      requestType: "get",
      requestHeader: "filters",
    });
    if (!data) {
      enqueueSnackbar("There was an error fetching the preset filter list", {
        variant: "warning",
      });
    } else {
      setPresetFilterList(data?.results);
    }
  };

  // gets master table data
  const getData = async () => {
    // let data = await sendRequest({ requestType: "get", requestHeader: "master/?platform=FreeVee%20FAST%20Timeline%20(FV)", requestParams: { limit: DATA_PAGE_PAGE_LIMIT } })
    let data = await sendRequest({
      requestType: "get",
      requestHeader: "master/",
      requestParams: {limit: DATA_PAGE_PAGE_LIMIT},
    });
    if (!data) {
      enqueueSnackbar("There was an error fetching the data", {
        variant: "warning",
      });
      setLoading(false);
    } else {
      setTableData(data);
    }
  };

  // ????
  const applyFilters = (model?: any) => {
    enqueueSnackbar("Filter configuration applied!", {variant: "success"});
  };

  // Saves user filter configuration
  const saveFilterConfiguration = async (
    filterName: string,
    isPrivate: boolean
  ) => {
    const pinned = apiRef.current.getPinnedColumns();
    const currentFilterConfiguration = {
      filters,
      columns: columnOrder,
      pinnedColumns: pinned,
    };

    const filterConfiguration = {
      name: filterName,
      filter_string: JSON.stringify(currentFilterConfiguration),
      private: isPrivate,
    };

    const result = await sendRequest({
      requestType: "post",
      requestHeader: "filters/",
      requestData: filterConfiguration,
    });

    if (!result) {
      enqueueSnackbar("There was an error saving your filter configuration", {
        variant: "warning",
      });
      setSavePresetDialogueOpen(false);
    } else {
      enqueueSnackbar("Filter configuration saved!", {variant: "success"});
      setSavePresetDialogueOpen(false);
      getPresetFilters();
    }
  };

  // Deletes user filter configuration
  const deleteFilterConfiguration = async (filterName: string) => {
    const result = await sendRequest({
      requestType: "delete",
      requestHeader: `filters/${filterName}/`,
    });
    if (!result) {
      enqueueSnackbar("Filter configuration deleted!", {variant: "success"});
      getPresetFilters();
    } else {
      enqueueSnackbar("There was an error deleting your filter configuration", {
        variant: "warning",
      });
    }
  };

  // Sends request to server to export data as Google Sheet
  const handleGoogleSheetsExport = async ({
    current_rows,
    current_columns,
  }: {
    current_rows: any;
    current_columns: any[];
  }) => {
    const baserockIds = Array.from(current_rows, ([name]) => name);

    if (baserockIds.length > 100000) {
      enqueueSnackbar("A maximum of 100000 rows can be exported at a time.", {
        variant: "warning",
      });
      return;
    }

    if (baserockIds.length < 1) {
      enqueueSnackbar("No rows selected", {variant: "warning"});
      return;
    }

    setLoading(true);

    const columns = current_columns.filter((item) => item !== "__check__");
    const response = await sendRequest({
      requestType: "post",
      requestHeader: `master/export/`,
      requestData: {assets: baserockIds, columns},
    });
    if (response?.uri) {
      setExportResultsDialogString(response.uri);
    } else if (response?.job_id) {
      enqueueSnackbar("The data is being exported.");
      setLoading(false);

      let progress = 0;

      const job_spinner = setInterval(async function () {
        const job_response = await sendRequest({
          requestType: "get",
          requestHeader: `jobs/${response.job_id}/`,
        });
        if (job_response.job_output.status === "FAILED") {
          enqueueSnackbar("There was an error exporting your data", {
            variant: "warning",
          });
          clearInterval(job_spinner);
          return;
        } else if (job_response.job_output.status === "DONE") {
          setExportResultsDialogString(job_response.job_output.uri);
          clearInterval(job_spinner);
        } else if (job_response.progress !== progress) {
          progress = job_response.progress;
          enqueueSnackbar(`Export: ${progress}%`);
        }
      }, 10000);
    } else {
      enqueueSnackbar("There was an error exporting your data", {
        variant: "warning",
      });
    }
    setLoading(false);
  };

  const handleImport = async (url: string) => {
    setImportMenuOpen(false);
    setLoading(true);
    const response = await sendRequest({
      requestType: "post",
      requestHeader: "master/import/",
      requestData: {url},
    });
    if (!response) {
      enqueueSnackbar("There was an error importing your sheet", {
        variant: "warning",
      });
    } else {
      enqueueSnackbar("Sheet import job created!", {variant: "info"});
      setJob(response);
    }
    setLoading(false);
  };

  return (
    <>
      <DataTable
        apiRef={apiRef}
        rawData={tableData}
        setLoading={setLoading}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        applyFilters={applyFilters}
        openPresetFilterSaveDialogue={() => setSavePresetDialogueOpen(true)}
        openImportDisplay={() => {
          if (!job) enqueueSnackbar("No Import Job to display!");
          else setImportDisplayOpen(true);
        }}
        presetFilterList={presetFilterList}
        modelMetadata={modelMetadata}
        handleGoogleSheetsExport={handleGoogleSheetsExport}
        limit={DATA_PAGE_PAGE_LIMIT}
        setColumnOrder={setColumnOrder}
        deleteFilterConfiguration={deleteFilterConfiguration}
        setImportMenuOpen={setImportMenuOpen}
      />

      <SavePresetFilterDialog
        open={savePresetDialogueOpen}
        saveFilterConfiguration={saveFilterConfiguration}
        closeFilterSaveDialogue={() => setSavePresetDialogueOpen(false)}
      />
      <ExportResultsDialog
        exportResultsDialogString={exportResultsDialogString}
        close={() => setExportResultsDialogString("")}
      />
      <ImportMenuDialog
        open={importMenuOpen}
        handleImport={handleImport}
        close={() => setImportMenuOpen(false)}
      />
      <ImportDisplay
        job={job}
        open={importDisplayOpen}
        close={() => setImportDisplayOpen(false)}
      />
    </>
  );
};

export default DataPage;
