import {useNavigate, useSearchParams} from "react-router-dom";
import {useSnackbar} from "notistack";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import backgroundImage from "../images/loginBackground.jpeg";
import {Button, Typography} from "@mui/material";
import {useEffect} from "react";
import sendRequest from "../services/sendRequest";
import useScript from "../hooks/useScript";

declare global {
  interface Window {
    google: any;
  }
}

const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {enqueueSnackbar} = useSnackbar();

  let client: any;

  useScript("https://accounts.google.com/gsi/client", () => {
    client = window.google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      ux_mode: "redirect",
      // redirect_uri: 'http://localhost:3000', // for local development
      redirect_uri: window.location.origin,
    });
  });

  const access = localStorage.getItem(
    `${process.env.REACT_APP_GCP_PROJECT}_access`
  );
  const refresh = localStorage.getItem(
    `${process.env.REACT_APP_GCP_PROJECT}_refresh`
  );

  useEffect(() => {
    const code = searchParams.get("code");

    if (access && refresh) navigate("/datapage");
    if (!code) return;

    const login = async () => {
      const response = await sendRequest({
        requestType: "get",
        requestHeader: `oauth-login?code=${code}`,
      });
      if (response) {
        const {access, refresh} = response;
        localStorage.setItem(
          `${process.env.REACT_APP_GCP_PROJECT}_access`,
          access
        );
        localStorage.setItem(
          `${process.env.REACT_APP_GCP_PROJECT}_refresh`,
          refresh
        );
        navigate("/datapage");
      } else {
        enqueueSnackbar("There was an error logging in", {variant: "warning"});
        setSearchParams("");
      }
    };

    login();
  }, []);

  return (
    <Grid container component="main" sx={{height: "100vh"}}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(239, 236, 234, 1)",
          boxShadow: "150px 0px 0px rgba(0,0,0,.1)",
        }}
      >
        <Box
          sx={{
            // my: 'auto',
            // mx: 4,
            minHeight: 150,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <Button onClick={() => console.log(getGoogleUrl())} >Test Login</Button> */}
          <Typography variant="h4" color="primary">
            Baserock
          </Typography>
          <Button onClick={() => client.requestCode()}>Login</Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
