import sendRequest from "../services/sendRequest"

export interface ColsAndFilters {
  label: any[]
  option: string
  dataType: 'string' | 'integer' | 'date' | 'float',
  readonly: boolean | null
}


// converts model names from master list to schema.
// Should this come from baserock? 
export const attributesList: any = {
  genre: 'Genre', //
  language: 'Language (Hierarcy)', //
  origin_country: 'Country of Origin', //
  ad_qc_status: 'Ad QC', //
  ad_clear_status: 'Ad Cleared', //
  captions_request: 'Captions Requested', //
  original_language: 'Original Language', //
  compliance_request: 'Compliance Requested', //
  compliance_status: 'Compliance Status', // 
  delivery_status: 'Delivery Status Type', //
  entity_type: 'catalogue_item_template', //
}

// converts model names from master list to schema.
export const attributeHierarchyList: any = {
  window_channels: 'Platform (Hierarchy)', //
  exploitation_platforms: 'Platform (Hierarchy)', 
  rights_in_territories: 'Territories Hierarchy - Rights (Detailed)',
  rights_in_platforms: 'Platform (Hierarchy)',
  rights_in_media: 'Media Hierarchy (LDS)'
}


// retrieves model metadata
const getModelMetadata = async ({ model }: { model: string | undefined }) => {
  let modelData
  modelData = await sendRequest({ requestType: "options", requestHeader: `${model}/` })
  const colsAndFilters = generateColsAndFilters(modelData.response)
  return colsAndFilters
}


// generates columns and filters from options data
const generateColsAndFilters = async (modelData: any, parentBreadcrumbs?: any[], resArr: ColsAndFilters[] = []) => {
  for (let key in modelData) {
    // Attributes are for fields only.
    if (modelData[key]['label'] === 'Attributes') continue
    // does the item have child elements?
    let hasChildren = Object.keys(modelData[key]).includes("children") || Object.keys(modelData[key]).includes("child")
    // does the item have parent elements? If so, concat itself to them.
    let breadcrumbs = parentBreadcrumbs && [...parentBreadcrumbs, modelData[key]['label']]
    // if modelData has children, we don't want to create an element
    if (hasChildren && key !== "restrictions" && key !== "exploitation") {
      let nextModelData
      if (key === 'rights_in') {
        nextModelData = modelData[key]['children'][0].fields
      } else {
        nextModelData = modelData[key]['children'] || modelData[key]['child']['children']
      }
      generateColsAndFilters(nextModelData, breadcrumbs || [modelData[key]['label']], resArr)
    
    } else if (key !== "children" && key !== "child") {

      let dataType
      
      if (Object.keys(attributeHierarchyList).includes(key)) dataType = 'attributeHierarchy'
      
      else if (Object.keys(attributesList).includes(key)) dataType = 'attribute'
      
      else if (key === "restrictions" || key === "exploitation") dataType = 'string'

      else dataType = modelData[key]['type']
      
      resArr.push({
        label: breadcrumbs || [modelData[key]['label']],
        option: key,
        dataType,

        // additional identifier for whats editable in the front end (used in dataTable)
        readonly: modelData[key]['read_only'] ?? true //modelData[]['read_only']
      })
    } else {
      console.log(`ERROR WITH GENERATING COLS AND FILTERS - key = ${key}, modelData = ${modelData}, parentBreadcrumbs = ${parentBreadcrumbs}`)
    }
  }
  return resArr
}


// transfoms {name: '', children: [...children]} to {name: '', parents: [...parents]}
const flattenTree = ({treeArr, resObj={}, parents}:{treeArr: any[], resObj?: any, parents?: string[]}) => {
  // If something non-iterable (eg. undefined) is passed, skip it.
  if (!(Symbol.iterator in Object(treeArr))) {
    console.log(`ERROR WITH FLATTENING TREE - treeArr = ${treeArr}, parents = ${parents}`)
    return
  }

  for (let attributeObject of treeArr) {
    const hasChildren = attributeObject?.children.length > 0

    resObj[attributeObject.value] = {...attributeObject, children: null, parents}

    if (hasChildren) {
      flattenTree({treeArr: attributeObject.children, resObj, parents: parents ? [...parents, attributeObject.value] : [attributeObject.value]})
    }
  }
 
  return resObj
}

export {
  getModelMetadata,
  generateColsAndFilters,
  flattenTree
}
