// External
import {MouseEvent, useState} from "react";

// Hooks
import {useSnackbar} from "notistack";

// MUI
import {
  IconButton,
  ListItem,
  ListItemButton,
  Menu,
  Tooltip,
} from "@mui/material";
import {Box} from "@mui/system";
import SaveIcon from "@mui/icons-material/Save";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {useGridApiContext} from "@mui/x-data-grid-pro";
import {Delete} from "@mui/icons-material";

// Pages
import {PresetFilter} from "../pages/dataPage";

interface PresetFiltersProps {
  presetFilterList: any[];
  filters: any[];
  openPresetFilterSaveDialogue: Function;
  loading: boolean;
  deleteFilterConfiguration: Function;
}

const PresetFilters = ({
  presetFilterList,
  filters,
  openPresetFilterSaveDialogue,
  loading,
  deleteFilterConfiguration,
}: PresetFiltersProps) => {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const selectPresetDialogueOpen = Boolean(anchorElement);

  const gridApi = useGridApiContext();
  const {enqueueSnackbar} = useSnackbar();

  const applyFilter = (model: any) => {
    const {filters, columns, pinnedColumns} = JSON.parse(model.filter_string);
    // apply new filters

    setSelectedFilter(model.name);
    gridApi.current.setFilterModel({items: filters});

    // apply column ordering and visibility
    const allColumns = gridApi.current.getAllColumns();
    allColumns.forEach(({field}: {field: string}) => {
      if (columns.includes(field)) {
        gridApi.current.setColumnVisibility(field, true);
        gridApi.current.setColumnIndex(field, columns.indexOf(field));
      } else {
        gridApi.current.setColumnVisibility(field, false);
      }
    });
    if (pinnedColumns) {
      gridApi.current.setPinnedColumns(pinnedColumns);
    }

    enqueueSnackbar("Filter configuration applied!", {variant: "success"});
    setAnchorElement(null);
  };

  return (
    <Box>
      <Tooltip
        title={
          presetFilterList.length < 1
            ? "No preset filters found"
            : "Select preset filter"
        }
      >
        <span>
          <IconButton
            onClick={(event: MouseEvent<HTMLElement>) =>
              setAnchorElement(event.currentTarget)
            }
            disabled={presetFilterList.length < 1 || loading}
          >
            <ListAltIcon
              color={
                presetFilterList.length < 1 || loading ? "disabled" : "success"
              }
            />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        style={{maxHeight: 400}}
        open={selectPresetDialogueOpen}
        anchorEl={anchorElement}
        onClose={() => setAnchorElement(null)}
      >
        {presetFilterList.length > 0 &&
          presetFilterList.map((presetFilter: PresetFilter, index: number) => (
            <ListItem
              disableGutters
              key={index}
              style={{
                backgroundColor:
                  selectedFilter === presetFilter.name
                    ? "lightblue"
                    : "inherit",
              }}
              secondaryAction={
                <IconButton
                  onClick={() => deleteFilterConfiguration(presetFilter.name)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemButton onClick={() => applyFilter(presetFilter)}>
                {presetFilter.name}
              </ListItemButton>
            </ListItem>
          ))}
      </Menu>
      <Tooltip
        title={
          filters.length < 1 || loading
            ? "No configuation found"
            : "Save Filter Configuration"
        }
      >
        <span>
          <IconButton
            onClick={() => {
              const currentVisibleColumns = gridApi.current
                .getVisibleColumns()
                .map(({field}: {field: string}) => field);
              openPresetFilterSaveDialogue({columns: currentVisibleColumns});
            }}
            disabled={filters.length < 1 || loading}
          >
            <SaveIcon
              color={filters.length < 1 || loading ? "disabled" : "success"}
            />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default PresetFilters;
