// import * as React from "react";
// import {useEffect, useRef, useState} from "react";
// import Box from "@mui/material/Box";
// import Slider from "@mui/material/Slider";
// import Typography from "@mui/material/Typography";
// import {GridFilterInputValueProps, useGridRootProps} from "@mui/x-data-grid";
// import SyncIcon from "@mui/icons-material/Sync";

// const dataGridProps: any = {
//   filterDebounceMs: 300,
//   // other DataGrid properties...
// };

// function RangeFilter(props: GridFilterInputValueProps) {
//   const rootProps = useGridRootProps();
//   const {item, applyValue, focusElementRef = null} = props;

//   const filterTimeout = useRef<any>();
//   const [filterValueState, setFilterValueState] = useState<[any, any]>(
//     item.value ?? [0, 86400] // Assuming initial range is 0s to 24 hours
//   );
//   const [applying, setIsApplying] = useState(false);

//   useEffect(() => {
//     return () => {
//       clearTimeout(filterTimeout.current);
//     };
//   }, []);

//   useEffect(() => {
//     const itemValue = item.value ?? [0, 86400];
//     setFilterValueState(itemValue);
//   }, [item.value]);

//   const updateFilterValue = (minValue: string, maxValue: string) => {
//     clearTimeout(filterTimeout.current);
//     setFilterValueState([minValue, maxValue]);

//     setIsApplying(true);
//     filterTimeout.current = setTimeout(() => {
//       setIsApplying(false);
//       applyValue({
//         ...item,
//         value: [minValue.toString(), maxValue.toString()],
//       });
//     }, dataGridProps.filterDebounceMs);
//   };

//   const handleSliderChange = (_event: Event, newValue: number | number[]) => {
//     const [minValue, maxValue] = newValue as number[];
//     updateFilterValue(minValue.toString(), maxValue.toString());
//   };

//   return (
//     <Box
//       sx={{
//         // display: "inline-flex",
//         flexDirection: "column",
//         alignItems: "center",
//         width: 150,
//         height: 80,
//         pl: "10px",
//       }}
//     >
//       <Typography variant="subtitle2" gutterBottom>
//         Select Time Range:
//       </Typography>
//       <Slider
//         value={filterValueState}
//         onChange={handleSliderChange}
//         valueLabelDisplay="auto"
//         valueLabelFormat={(value) => secondsToTime(value)}
//         min={0}
//         max={7200} // Assuming a range of 0s to 24 hours
//       />
//       {applying && <SyncIcon />}
//     </Box>
//   );
// }

// export default RangeFilter;

// // Helper function to convert seconds to time string for display
// function secondsToTime(seconds: number): string {
//   const hh = Math.floor(seconds / 3600);
//   const mm = Math.floor((seconds % 3600) / 60);
//   const ss = Math.floor(seconds % 60);
//   const ms = Math.floor((seconds % 1) * 1000);
//   return `${String(hh).padStart(2, "0")}:${String(mm).padStart(
//     2,
//     "0"
//   )}:${String(ss).padStart(2, "0")}.${String(ms).padStart(3, "0")}`;
// }

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import {GridFilterInputValueProps, useGridRootProps} from "@mui/x-data-grid";
import SyncIcon from "@mui/icons-material/Sync";
import {Grid} from "@mui/material";

//const breakPoints = [0, 5 * 60, 15 * 60, 60 * 60, 2 * 60 * 60];
const dataGridProps: any = {
  filterDebounceMs: 300,
  // other DataGrid properties...
};

function RangeFilter(props: GridFilterInputValueProps) {
  const {item, applyValue} = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[any, any]>(
    item.value ?? [0, 1200] // Assuming initial range is 0s to 2 hours
  );
  const [applying, setIsApplying] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [0, 1200];
    setFilterValueState(itemValue);
  }, [item.value]);

  const customLogScale = (value: number) => {
    if (value === 0) return 0;
    if (value <= 300) return value;
    if (value <= 600) return (value - 300) * 2 + 300;
    if (value <= 900) return (value - 600) * 9 + 900;
    if (value <= 1200) return (value - 900) * 12 + 3600;
    return 7200;
  };
  // const customLogScale = (value: number) => {
  //   if (value === 0) return 0;
  //   if (value <= 5 * 60) return value;
  //   if (value <= 15 * 60) return (value - 5 * 60) * (60 / 10) + 5 * 60;
  //   if (value <= 60 * 60) return (value - 15 * 60) * (60 / 45) + 15 * 60;
  //   if (value <= 2 * 60 * 60) return (value - 60 * 60) * (60 / 60) + 60 * 60;
  //   return 2 * 60 * 60;
  // };

  const updateFilterValue = (minValue: string, maxValue: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([minValue, maxValue]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({
        ...item,
        value: [minValue.toString(), maxValue.toString()],
      });
    }, dataGridProps.filterDebounceMs);
  };

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    const [logMinValue, logMaxValue] = newValue as number[];
    const minValue = logMinValue;
    const maxValue = logMaxValue;
    updateFilterValue(minValue.toString(), maxValue.toString());
  };

  const formatLogValue = (logValue: number) => {
    const linearValue = Math.pow(10, customLogScale(logValue));
    return secondsToTime(linearValue);
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        width: 150,
        pl: "10px",
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Select Time Range:
      </Typography>
      <Slider
        value={filterValueState}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => (value === 1200) ? '∞' : secondsToTime(customLogScale(value))}
        min={0}
        max={1200}
        // min={0}
        //max={7200}
        marks={[
          {value: 300, label: "5m"},
          {value: 600, label: "15m"},
          {value: 900, label: "1h"},
          {value: 1200, label: "∞"},
        ]}
      />
      {/* {applying && <SyncIcon />} */}
    </Box>
  );
}

export default RangeFilter;

// Helper function to convert seconds to time string for display
function secondsToTime(seconds: number): string {
  const hh = Math.floor(seconds / 3600);
  const mm = Math.floor((seconds % 3600) / 60);
  const ss = Math.floor(seconds % 60);
  const ms = Math.floor((seconds % 1) * 1000);
  return `${String(hh).padStart(2, "0")}:${String(mm).padStart(
    2,
    "0"
  )}:${String(ss).padStart(2, "0")}.${String(ms).padStart(3, "0")}`;
}
