// MUI
import { MenuItem } from "@mui/material";
import { GridToolbarExportContainer, GridCsvExportMenuItem, useGridApiContext, GridExportMenuItemProps } from "@mui/x-data-grid";

interface GoogleSheetsExportMenuAltProps extends GridExportMenuItemProps<{}> {
  handleGoogleSheetsExport: Function,
  loading: boolean,
  setImportMenuOpen: Function
}

const GoogleSheetsExportMenu = ({ handleGoogleSheetsExport, loading, setImportMenuOpen, ...props }: GoogleSheetsExportMenuAltProps) => {

  const apiRef = useGridApiContext();
  const { hideMenu } = props

  return (
    <MenuItem

      onClick={() => {
        const current_rows = apiRef.current.getSelectedRows()
        const current_columns = apiRef.current.getAllColumns().filter(item => !item.hide).map(item => item.field)
        handleGoogleSheetsExport({ current_rows, current_columns })
        hideMenu?.()
      }}
    >
      Export to Google Sheets
    </MenuItem>
  );
};


const ImportDataMenuItem = ({setImportMenuOpen}:{setImportMenuOpen: Function}) => 
  <MenuItem
    onClick={() => setImportMenuOpen(true)}
  >
    Import From Google Sheet
  </MenuItem>


const CustomExportButton = ({ handleGoogleSheetsExport, loading, setImportMenuOpen, ...props }: GoogleSheetsExportMenuAltProps) => (
  <GridToolbarExportContainer
    nonce={''}
    onResize={() => null}
    onResizeCapture={() => null}
    disabled={loading}
  >
    <GridCsvExportMenuItem />
    <GoogleSheetsExportMenu
      handleGoogleSheetsExport={handleGoogleSheetsExport}
      loading={loading}
      setImportMenuOpen={setImportMenuOpen}
      {...props}
    />
    <ImportDataMenuItem 
      setImportMenuOpen={setImportMenuOpen}
    />
  </GridToolbarExportContainer>
);

export default CustomExportButton
