import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from "@mui/material";


const ExportResultsDialog = ({exportResultsDialogString, close}:{exportResultsDialogString: string, close: Function}) => 
    <Dialog
        open={exportResultsDialogString.length > 0}
    >
        <DialogTitle>
            <Link
                href={exportResultsDialogString}
                target="_blank"
            >View Exported Data</Link>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                An email has also been sent to your inbox with the exported data!
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => close()}>Close</Button>
        </DialogActions>
    </Dialog>

export default ExportResultsDialog
