import {useState, useContext} from "react";
import {
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps,
} from "@mui/x-data-grid-pro";
import Select, {SelectChangeEvent} from "@mui/material/Select";

import {
  attributesList,
  attributeHierarchyList,
  flattenTree,
} from "../../utils/dataUtils";

import {MenuItem, Box} from "@mui/material";
import {
  AttributeContextType,
  AttributeContext,
} from "../../context/attributeContextProvider";

//import RangeFilter from "../../alerts/rangeFilter";
import DateRangeFilter from "../../alerts/dateRangeFilter";
import RangeFilter from "../../alerts/rangeFilter";
import BeforeAfterFilter from "../../alerts/beforeAfterFilter";

const AttributeInput = (props: GridFilterInputValueProps) => {
  const {attributeTree} = useContext(AttributeContext) as AttributeContextType;
  const [options, setOptions] = useState<string[]>();
  const {item, applyValue} = props;

  const treeIndex = Object.keys(attributesList).includes(item.columnField)
    ? attributesList[item.columnField]
    : Object.keys(attributeHierarchyList).includes(item.columnField)
    ? attributeHierarchyList[item.columnField]
    : false;

  const handleChange = (event: SelectChangeEvent<typeof options>) => {
    const {
      target: {value},
    } = event;
    setOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    applyValue({...item, value});
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 63,
        width: 180,
        pl: "20px",
      }}
    >
      <Select
        style={{width: "100%"}}
        multiple
        value={item.value || []}
        onChange={handleChange}
      >
        {Object.keys(attributeTree).length > 0 &&
          item?.columnField &&
          treeIndex &&
          Object.keys(
            flattenTree({
              treeArr: attributeTree[treeIndex],
            })
          )
            .sort()
            .map((attribute: any, index: number) => (
              <MenuItem key={index} value={attribute}>
                {attribute}
              </MenuItem>
            ))}
      </Select>
    </Box>
  );
};

const LinearChannelDropdown = (props: GridFilterInputValueProps) => {
    // Hardcoded list of linear channels
    const linearChannels = [
      "Don't Tell The Bride",
      "History Hit",
      "Real Crime Alpha",
      "Real Crime Beta",
      "Real Crime Gamma",
      "Real Crime WW",
      "Real Life",
      "Real Stories",
      "Real Wild",
      "The Chat Show Channel",
      "Wonder",
    ];
  
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const { item, applyValue } = props;
  
    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const { target: { value } } = event;
      const selected = typeof value === 'string' ? value.split(',') : value;
      setSelectedChannels(selected);
      applyValue({ ...item, value: selected });
    };
  
    return (
      <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', height: 63, width: 180, pl: '20px' }}>
        <Select
          style={{ width: '100%' }}
          multiple
          value={selectedChannels}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
        >
          {linearChannels.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const linearChannelSelectOperator: GridFilterOperator[] = [
    {
      label: "Any of",
      value: "any of",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        return (params): boolean => {
          const paramValue = params.value; // This is the value of the `linear_channel` field from the row
          if (!paramValue) return false; // If there's no value, return false
  
          // Split the paramValue into an array if it's a delimited string
          const linearChannelArray = paramValue.split(';').map((item: string) => item.trim());
  
          // Check if any of the selected filter values are present in the linearChannelArray
          return filterItem.value.some((selectedValue: string) => 
            linearChannelArray.includes(selectedValue)
          );
        };
      },
  
      InputComponent: LinearChannelDropdown,
    },
  ];
  
  const SecondryChannelDropdown = (props: GridFilterInputValueProps) => {
    // Hardcoded list of linear channels
    const secondryChannels = [
      "InsightTV",
      "LG",
      "Plex",
      "Roku",
      "Samsung AU",
      "STV",
      "Veely",
      "Vizio",
    ];
  
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const { item, applyValue } = props;
  
    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const { target: { value } } = event;
      const selected = typeof value === 'string' ? value.split(',') : value;
      setSelectedChannels(selected);
      applyValue({ ...item, value: selected });
    };
  
    return (
      <Box sx={{ display: 'inline-flex', flexDirection: 'row', alignItems: 'center', height: 63, width: 180, pl: '20px' }}>
        <Select
          style={{ width: '100%' }}
          multiple
          value={selectedChannels}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
        >
          {secondryChannels.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const secoundryChannelSelectOperator: GridFilterOperator[] = [
    {
      label: "Any of",
      value: "any of",
      getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
  
        return (params): boolean => {
          const paramValue = params.value; // This is the value of the `secondry_channel` field from the row
          if (!paramValue) return false; // If there's no value, return false
  
          // Split the paramValue into an array if it's a delimited string
          const secondryChannelArray = paramValue.split(';').map((item: string) => item.trim());
  
          // Check if any of the selected filter values are present in the secondryChannelArray
          return filterItem.value.some((selectedValue: string) => 
            secondryChannelArray.includes(selectedValue)
          );
        };
      },
  
      InputComponent: SecondryChannelDropdown,
    },
  ];

const attributeSelectorDefault: GridFilterOperator[] = [
  // {
  //   label: "Between",
  //   value: "between",
  //   getApplyFilterFn: (filterItem: GridFilterItem) => {
  //     if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
  //       return null;
  //     }
  //     if (filterItem.value[0] == null || filterItem.value[1] == null) {
  //       return null;
  //     }

  //     return ({value}) => {
  //       return (
  //         value !== null &&
  //         filterItem.value[0] <= value &&
  //         value <= filterItem.value[1]
  //       );
  //     };
  //   },
  //   InputComponent: DateRangeFilter,
  // },
  {
    label: "is blank",
    value: "is blank",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      //return (params): boolean => (!params?.value?.length || params.value.length === null)
      return (params) => !params?.value?.toString().trim();
    },
  },

  {
    label: "Is empty",
    value: "is empty",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean =>
        !params?.value?.length || params.value.length < 1;
    },
  },
  {
    label: "Is not empty",
    value: "is not empty",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean => params?.value?.length > 1;
    },
  },
];

const attributeSelectOperator: GridFilterOperator[] = [
  {
    label: "all of",
    value: "all of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        if (params.value === undefined || params.value === null) {
          // Keep rows with blank values
          return true;
        }

        // params is the row.column
        // if (!params.value) return filterItem.value.includes(''); //return false
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "None of",
    value: "none of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        if (params.value === undefined || params.value === null) {
          // Keep rows with blank values
          return false;
        }

        // params is the row.column
        // if (!params.value) return filterItem.value.includes(''); //return false
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "Any of",
    value: "any of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO PAUL - change column prop type to extended mui props.
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params): boolean => {
        // params is the row.column
        if (!params.value) return false;
        // attributeValue is a list of attributes inputted by the user
        for (let attributeValue of filterItem.value) {
          // will evaluate to false if the row does not include one of the attributes
          if (params.value.split("; ").includes(attributeValue)) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  ...attributeSelectorDefault,
];

// Hierarchy filter version looks for parents in the attribute tree.
const attributeHierarchySelectOperator: GridFilterOperator[] = [
  // {
  //   label: "Between",
  //   value: "between",
  //   getApplyFilterFn: (filterItem: GridFilterItem) => {
  //     if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
  //       return null;
  //     }
  //     if (filterItem.value[0] == null || filterItem.value[1] == null) {
  //       return null;
  //     }

  //     return ({value}) => {
  //       return (
  //         value !== null &&
  //         filterItem.value[0] <= value &&
  //         value <= filterItem.value[1]
  //       );
  //     };
  //   },
  //   InputComponent: RangeFilter,
  // },
  {
    label: "All of",
    value: "all of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;

        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, highest to lowest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [...attributeTree[attributeValue].parents, attributeValue]
            : [attributeValue];

          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );

          // if the row does not include one of the attributes, filter it out and move on.
          if (!hasAttribute) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "None of",
    value: "none of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;
        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, lowest to highest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [
                ...attributeTree[attributeValue].parents,
                attributeValue,
              ].reverse()
            : [attributeValue];
          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );
          // if the row does not include one of the attributes, filter it out and move on.
          if (hasAttribute) return false;
        }
        return true;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "Any of",
    value: "any of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;
        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // we expand each user attribute into a list with parents, highest to lowest in the tree.
          const attributeListWithParents = attributeTree[attributeValue].parents
            ? [...attributeTree[attributeValue].parents, attributeValue]
            : [attributeValue];
          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasAttribute = attributeListWithParents.some(
            (attribute: string) => params.value.split("; ").includes(attribute)
          );
          // if the row includes one of the attributes, pass the row and move on.
          if (hasAttribute) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  {
    label: "Has Child of",
    value: "has child of",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const {attributeTree} = column.filterOperatorProps;

      return (params): boolean => {
        // params is the row.column being filtered.
        if (!params.value) return false;
        // filterItem.value is an array of attributes that the user has inputted.
        for (let attributeValue of filterItem.value) {
          // evaluates to false if the row does not include one of the full list of attributes with parents.
          const hasChild = params.value
            .split("; ")
            .some((attribute: string) => {
              // params.value.split("; ").includes(attribute)
              if (attributeTree[attribute].parents?.includes(attributeValue))
                return true;
            });
          // we expand each user attribute into a list with parents, highest to lowest in the tree.
          // const attributeListWithParents = attributeTree[attributeValue].parents ? [...attributeTree[attributeValue].parents, attributeValue] : [attributeValue]
          // if the row includes one of the attributes, pass the row and move on.
          if (hasChild) return true;
        }
        return false;
      };
    },
    InputComponent: AttributeInput,
  },
  ...attributeSelectorDefault,
];

const DateRangeSelectOperator: GridFilterOperator[] = [
  {
    label: "Between",
    value: "between",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({value}) => {
        return (
          value !== null &&
          filterItem.value[0] <= value &&
          value <= filterItem.value[1]
        );
      };
    },
    InputComponent: DateRangeFilter,
  },
  {
    label: "Before",
    value: "before",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null) {
        return null;
      }

      return ({value}) => {
        return value !== null && value < filterItem.value;
      };
    },
    InputComponent: BeforeAfterFilter,
  },
  {
    label: "After",
    value: "after",
    getApplyFilterFn: (filterItem) => {
      if (filterItem.value == null) {
        return null;
      }

      return ({value}) => {
        return value !== null && value > filterItem.value;
      };
    },
    InputComponent: BeforeAfterFilter,
  },

  {
    label: "Past Seven Days",
    value: "pastSevenDays",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO: Change column prop type to extended MUI props if needed.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }

      // Get today's date
      const today = new Date();

      // Calculate the date seven days ago
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);

      return (params) => {
        // Assuming the column values are dates, adjust the condition based on your data
        const dateValue = new Date(params?.value);
        return dateValue >= sevenDaysAgo && dateValue <= today;
      };
    },
  },
  {
    label: "Past Thirty Days",
    value: "pastThirtyDays",
    getApplyFilterFn: (filterItem: GridFilterItem, column: any) => {
      // TODO: Change column prop type to extended MUI props if needed.
      if (!filterItem.columnField || !filterItem.operatorValue) {
        return null;
      }

      // Get today's date
      const today = new Date();

      // Calculate the date thirty days ago
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);

      return (params) => {
        // Assuming the column values are dates, adjust the condition based on your data
        const dateValue = new Date(params?.value);
        return dateValue >= thirtyDaysAgo && dateValue <= today;
      };
    },
  },

  ...attributeSelectorDefault,
];

const parseTime = (timeString: string): number => {
  const [hh, mm, ss, ms] = timeString.split(/[:.]/).map(Number);
  return hh * 3600 + mm * 60 + ss + ms / 1000;
};

const RangeSelectOperator: GridFilterOperator[] = [
  // {
  //   label: "Time Range",
  //   value: "timeRange",
  //   getApplyFilterFn: (filterItem) => {
  //     if (!Array.isArray(filterItem.value)) {
  //       return null;
  //     }
  //     if (filterItem.value[0] == null || filterItem.value[1] == null) {
  //       return null;
  //     }

  //     const [minTime, maxTime] = filterItem.value;

  //     return ({value}) => {
  //       if (value === null) {
  //         return false;
  //       }

  //       const entryTime = parseTime(value);

  //       return (
  //         entryTime >= parseTime(minTime) && entryTime <= parseTime(maxTime)
  //       );
  //     };
  //   },
  //   InputComponent: RangeFilter,
  // },
  {
    label: "Time Range",
    value: "timeRange",
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value)) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      const [_minTime, _maxTime] = filterItem.value;

      // Convert from linear scale into logarithmic time:
      const customLogScale = (value: any) => {
        value = Number(value);
        if (value === 0) return 0;
        if (value < 300) return value;
        if (value < 600) return (value - 300) * 2 + 300;
        if (value < 900) return (value - 600) * 9 + 900;
        if (value < 1200) return (value - 900) * 12 + 3600;
        return 3600000;
      };
      const minTime = customLogScale(_minTime), maxTime = customLogScale(_maxTime);


      return ({value}) => {
        if (value === null) {
          return false;
        }

        const entryTime = parseTime(value);

        return entryTime >= minTime && entryTime <= maxTime;
      };
    },
    InputComponent: RangeFilter,
  },
  ...attributeSelectorDefault,
];

export {
  secoundryChannelSelectOperator,
  linearChannelSelectOperator,
  attributeSelectOperator,
  attributeHierarchySelectOperator,
  DateRangeSelectOperator,
  RangeSelectOperator,
};
