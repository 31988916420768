// External
import {Link, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";

// MUI
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import {Button} from "@mui/material";

// Internal
import {AppBar, Drawer, DrawerHeader, drawerwidth} from "../styles/theme";

interface MiniDrawerProps {
  open: boolean;
  setOpen: Function;
  setDarkMode: Function;
  darkMode: boolean;
}

const MiniDrawer = ({
  open,
  setOpen,
  setDarkMode,
  darkMode,
}: MiniDrawerProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_access`);
    localStorage.removeItem(`${process.env.REACT_APP_GCP_PROJECT}_refresh`);
    navigate("/");
  };

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      <AppBar position="fixed" open={open} drawerwidth={drawerwidth}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && {display: "none"}),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{cursor: "default"}}
              variant="h6"
              noWrap
              component="div"
            >
              Baserock
            </Typography>
            <Box>
              <Switch
                color="secondary"
                onChange={() => setDarkMode((prevState: Boolean) => !prevState)}
                checked={darkMode}
              />
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleLogout()}
              >
                Log out
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} drawerwidth={drawerwidth}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              displayName: "Profile",
              linkTo: "/account/",
            },
            {
              displayName: "Asset Information",
              linkTo: "/asset",
            },
          ].map((item) => (
            <ListItem
              key={item.displayName}
              disablePadding
              sx={{display: "block"}}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                component={Link}
                to={item.linkTo}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText
                  primary={item.displayName}
                  sx={{opacity: open ? 1 : 0}}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
};

export default MiniDrawer;
