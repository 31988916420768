import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText } from "@mui/material"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import React, { Fragment, useEffect } from "react"
import { useState } from "react"
import sendRequest from "../services/sendRequest"

export interface Job {
  job_id: string
  created_at: string
}

interface JobOutput {
  errors: Record<string, string> | null
  sheet_id: string
  status: "PENDING" | "DONE" | "FAILED"
  successes: Array<string> | null
}

interface JobResponse {
  completed_at: string
  created_at: string
  job_id: string
  job_output: JobOutput
  job_type: number
  owner: number
  progress: number
}

const ImportDisplay = ({job, open, close}:{job: Job | null, open: boolean, close: Function}) => {

  const [data, setData] = useState<JobResponse | null>(null)

  useEffect(() => {
    setData(null)
    const interval = setInterval(async () => {
      if (!(job?.job_id ?? null)) {
        clearInterval(interval)
        return
      }

      const res = await sendRequest({requestType: "get", requestHeader: `jobs/${job?.job_id}/`})
      if (!res) return
      
      setData(res)
      if (res.job_output.status !== "PENDING") {
        clearInterval(interval)
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [job?.job_id])

  if (!(job || data)) return null

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle>{`Import Job Progress`}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {`Start Time: ${job?.created_at.slice(0, 10)} ${job?.created_at.slice(11, 19)}`}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress 
              variant={data?.progress == 100 ? "determinate" : "indeterminate"}
              value={data?.progress ?? 0}
            />
          </Box>
          <Box sx={{ minWidth: 35, paddingLeft: '1rem' }}>
            <Typography variant="h6" color="text.secondary">{`${Math.round(
              data?.progress ?? 0
            )}%`}</Typography>
          </Box>
        </Box>
      </DialogContent>
      { 
        (data?.job_output.successes?.length ?? 0) > 0  && 
        <DialogContent dividers>
          <Typography>
            {`Successfully imported ${data?.job_output.successes?.length || 0} rows...`}
          </Typography>
        </DialogContent>
      }
      { 
        Object.keys(data?.job_output.errors ?? 0).length > 0 && 
        <DialogContent dividers>
          <Typography>Errors...</Typography>
          <List>
            <Divider />
            {
              Object.entries(data?.job_output.errors ?? {}).map(([id, err]) => {
                return (
                  <Fragment
                    key={id}
                  >
                    <ListItem>
                      <ListItemText
                        primary={`Baserock ID: ${id}`}
                        secondary={err}
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                )
              })
            }
          </List>
        </DialogContent>
      }
      <DialogActions>
        <Button 
          onClick={() => close()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportDisplay
