import { createContext, useEffect, useState } from "react"
import sendRequest from "../services/sendRequest"
import { Outlet } from "react-router-dom";

export interface Attribute {
  additional_metadata: string;
  children: AttributeTree[]
  id: number
  managed: boolean
  rl_attribute_id: number
  type: string
  value: string
}
export interface AttributeTree {
  [key: string]: Attribute[]
}

interface TemplateProps {
  template_name: string
  id: number
  rl_template_id: number
  process_name: string
}

export type AttributeContextType = {
  attributeTree: AttributeTree
};


export const AttributeContext = createContext<AttributeContextType | null>(null);

const AttributeContextProvider = () => {

  const [attributeTree, setAttributeTree] = useState<AttributeTree>({})

  useEffect(() => {
    (async () => {
      const attributeData: AttributeTree = await sendRequest({ requestType: 'get', requestHeader: `attribute/tree/` })
      const templateData = await sendRequest({ requestType: 'get', requestHeader: `template/` })
      await templateData.results.forEach(({template_name, id, rl_template_id, process_name}:TemplateProps) => {
        if (process_name === "Catalog Workflow"){

          const templateToAttribute: Attribute = {
            id,
            managed: true,
            rl_attribute_id: rl_template_id,
            type: "catalogue_item_template",
            value: template_name,
            additional_metadata: "",
            children: []
          }
          
          if ("catalogue_item_template" in attributeData){
            attributeData["catalogue_item_template"].push(templateToAttribute)
          } else {
            attributeData["catalogue_item_template"] = [templateToAttribute]
          }
        }
      })
      setAttributeTree(attributeData)
    })()
  }, [])

  return (
    <AttributeContext.Provider value={{ attributeTree }} >
      <Outlet />
    </AttributeContext.Provider>
  )

}

export default AttributeContextProvider
