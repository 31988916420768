import * as React from "react";
import Box from "@mui/material/Box";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {GridFilterInputValueProps, useGridRootProps} from "@mui/x-data-grid";
import SyncIcon from "@mui/icons-material/Sync";

const dataGridProps: any = {
  filterDebounceMs: 300,
  // other DataGrid properties...
};

export default function DateRangeFilter(props: GridFilterInputValueProps) {
  const rootProps = useGridRootProps();
  const {item, applyValue, focusElementRef = null} = props;

  const filterTimeout = React.useRef<any>();
  const [filterValueState, setFilterValueState] = React.useState<
    [string, string]
  >(item.value ?? ["", ""]);
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? ["", ""];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (startDate: string, endDate: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([startDate, endDate]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({...item, value: [startDate, endDate]});
    }, dataGridProps.filterDebounceMs);
  };

  const handleEndDateChange: TextFieldProps["onChange"] = (event) => {
    const newEndDate = event.target.value;
    updateFilterValue(filterValueState[0], newEndDate);
  };
  const handleStartDateChange: TextFieldProps["onChange"] = (event) => {
    const newStartDate = event.target.value;
    updateFilterValue(newStartDate, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "end",
        height: 48,
        pl: "20px",
      }}
    >
      <TextField
        name="start-date-input"
        variant="standard"
        value={filterValueState[0]}
        onChange={handleStartDateChange}
        type="date" // Use "date" type for date input
        inputRef={focusElementRef}
        sx={{mr: 2}}
      />
      <TextField
        name="end-date-input"
        variant="standard"
        value={filterValueState[1]}
        onChange={handleEndDateChange}
        type="date" // Use "date" type for date input
        InputProps={applying ? {endAdornment: <SyncIcon />} : {}}
      />
    </Box>
  );
}
